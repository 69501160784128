import {
    GAME_COMMAND,
    OPEN_LINK,
    OPEN_NEWS_ARTICLE,
} from "supercell-frontend-base/src/constants/types/commands";

export const mutateOpenLinkHack = (protocol) => (payload) => {
    if (payload.indexOf(protocol) === 0) return { type: GAME_COMMAND, payload };

    if (payload.indexOf(location.origin) > -1) {
        const newsIndex = payload.indexOf("/news/");

        if (newsIndex > -1) {
            const { groups } = /#\/(?<lang>.*)\/news/.exec(payload) || {
                groups: { lang: "en" },
            };
            const { lang } = groups;
            const params = payload.substr(newsIndex + "news/".length + 1);
            const [id, slug] = params.split("/");

            return {
                type: OPEN_NEWS_ARTICLE,
                payload: {
                    lang,
                    id,
                    slug,
                },
            };
        }
    }

    return {
        type: OPEN_LINK,
        payload,
    };
};
