export const localization: LocalizationConfig = {
    default: "en",
    available: [
        "en",
        "zh",
        "fr",
        "de",
        "id",
        "it",
        "ja",
        "ko",
        "pt",
        "ru",
        "es",
    ],
    locales: {
        en: {
            fonts: {
                primaryFont: "Clash",
                secondaryFont: "roboto_slabbold",
            },
        },
        zh: {
            rtl: false,
            fonts: {
                primaryFont: "clash-royale-cn",
                secondaryFont: "roboto_slabbold",
            },
        },
        fr: {
            fonts: {
                primaryFont: "Clash",
                secondaryFont: "roboto_slabbold",
            },
        },
        de: {
            fonts: {
                primaryFont: "Clash",
                secondaryFont: "roboto_slabbold",
            },
        },
        id: {
            fonts: {
                primaryFont: "Clash",
                secondaryFont: "roboto_slabbold",
            },
        },
        it: {
            fonts: {
                primaryFont: "Clash",
                secondaryFont: "roboto_slabbold",
            },
        },
        ja: {
            fonts: {
                primaryFont: `"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro", "ＭＳ ゴシック","MS Gothic", Arial, sans-serif`,
                secondaryFont: "Arial, sans-serif",
                titleBoldValue: "bold",
            },
        },
        ko: {
            fonts: {
                primaryFont: "SC COC Display Bold",
                secondaryFont: "roboto_slabbold",
                titleBoldValue: "bold",
            },
        },
        pt: {
            fonts: {
                primaryFont: "Clash",
                secondaryFont: "roboto_slabbold",
            },
        },
        ru: {
            fonts: {
                primaryFont: "Clash",
                secondaryFont: "roboto_slabbold",
            },
        },
        es: {
            fonts: {
                primaryFont: "Clash",
                secondaryFont: "roboto_slabbold",
            },
        },
    },
};
