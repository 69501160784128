import React from "react";

import { Inbox } from "supercell-frontend-base/src/components/inbox";
import { DebugInfo } from "supercell-frontend-base/src/components/debug-info";

import styles from "./styles.modules.scss";

export const AppView = (props) => {
    return (
        <div className={`app ${styles.app}`}>
            <Inbox {...props} />

            <DebugInfo />
        </div>
    );
};
