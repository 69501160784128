import React from "react";
import { withSentryTracing } from "supercell-frontend-base/src/tracing/sentry";
import { SnowplowContextProvider } from "supercell-frontend-base/src/analytics/snowplow";
import { AppController } from "./controller";

import { ClashRoyaleGameParams } from "../../validation/game-params";
import {
    GameParamsContextProvider,
    DEFAULT_STATE,
} from "supercell-frontend-base/src/stores/gameParams";

export const App = withSentryTracing((props: any) => {
    return (
        <GameParamsContextProvider<ClashRoyaleGameParams>
            initialState={DEFAULT_STATE}
            gameParamsParser={ClashRoyaleGameParams}
        >
            <SnowplowContextProvider
                config={{
                    appId: window.SNOWPLOW_APP_ID,
                    collectorUrl: window.SNOWPLOW_COLLECTOR_URL,
                }}
            >
                <AppController {...props} />
            </SnowplowContextProvider>
        </GameParamsContextProvider>
    );
});
