import React, { useEffect } from "react";
import { useLocation, Route, Routes } from "react-router-dom";

import { TRACK_EVENT } from "supercell-frontend-base/src/constants/types";
import { useSendCommand } from "supercell-frontend-base/src/hooks/use-send-command";
import { useSnowplowContext } from "supercell-frontend-base/src/analytics/snowplow";

import { EsportView } from "./view";

import { EsportArticle } from "./esport-article";

export const EsportController = () => {
    const loc = useLocation();
    const { pathname: pagePath } = loc;
    const sendCommand = useSendCommand();
    const { pageView } = useSnowplowContext();

    useEffect(() => {
        pageView("Esport");
        sendCommand(TRACK_EVENT, {
            event: "Pageview",
            pagePath,
            pageTitle: "esport",
        });

        window.history.pushState({ home: true }, "");
    }, []);

    const EsportContent = () => {
        return (
            <Routes>
                <Route path="/:id/:slug/:variant" element={<EsportArticle />} />

                <Route path="/:id/:slug" element={<EsportArticle />} />

                <Route path="/:id" element={<EsportArticle />} />

                <Route path="/" element={<EsportView />} />
            </Routes>
        );
    };

    return <EsportContent />;
};
