import React from "react";
import {
    newTracker,
    enableActivityTracking,
    trackPageView,
} from "@snowplow/browser-tracker";

const defaultPagePingConfiguration = {
    minimumVisitLength: 5,
    heartbeatDelay: 20,
};

function init(appId: string, collectorUrl: string) {
    newTracker("sp1", collectorUrl, {
        appId,
        plugins: [],
    });
    enableActivityTracking(defaultPagePingConfiguration);
}

function pageView(pageTitle: string) {
    trackPageView({ title: pageTitle });
}

const SnowplowContext = React.createContext<{
    pageView: typeof pageView;
}>({
    pageView,
});

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

export const SnowplowContextProvider = ({
    children,
    config,
}: {
    children: JSX.Element | JSX.Element[];
    config: { appId: string; collectorUrl: string };
}): JSX.Element | null => {
    const isConfigured = config.appId && config.collectorUrl;

    if (isConfigured) {
        init(config.appId, config.collectorUrl);
    }
    return (
        <SnowplowContext.Provider
            value={{ pageView: isConfigured ? pageView : noop }}
        >
            {children}
        </SnowplowContext.Provider>
    );
};

export const useSnowplowContext = () => React.useContext(SnowplowContext);
