import React from "react";
import styles from "./styles.modules.scss";

type DebugInfoViewProps = {
    onClick: () => void;
};

export const DebugInfoView = ({ onClick }: DebugInfoViewProps) => (
    <div onClick={onClick} className={`${styles.debug_info} `}></div>
);
