import React from "react";
import { OPEN_LINK } from "supercell-frontend-base/src/constants/types";
import { useSendCommand } from "supercell-frontend-base/src/hooks/use-send-command";
import { ContentfulSocialLinks } from "supercell-frontend-base/src/components/contentful-social-links";
import styles from "./styles.modules.scss";

type EsportFooterProps = {
    links: SocialLink[];
};

const EsportFooter = (props: EsportFooterProps) => {
    const { links } = props;
    const sendCommand = useSendCommand();
    const crlUrl = "https://esports.clashroyale.com";
    const handleOnClick = () => {
        sendCommand(OPEN_LINK, crlUrl);
    };
    return (
        <div className={styles.footer}>
            <ContentfulSocialLinks
                links={links}
                titleLabel="globalText.followMore"
            />

            <div className={styles.about}>
                <a onClick={handleOnClick}>About CRL</a>
            </div>
        </div>
    );
};

export { EsportFooter };
