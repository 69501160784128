import React from "react";
import classNames from "classnames";
import { useSendCommand } from "supercell-frontend-base/src/hooks/use-send-command";
import { OPEN_LINK } from "supercell-frontend-base/src/constants/types/commands";
import { Image } from "supercell-frontend-base/src/components/image";
import { formatTime } from "supercell-frontend-base/src/utils/format-time";

import styles from "./styles.modules.scss";
import { LiveSlide } from "supercell-frontend-base/src/components/esport/types";

type EsportCarouselProps = {
    slides: LiveSlide[];
    currentTime: Date;
};

const IncomingLiveEventsBannerView = (props: EsportCarouselProps) => {
    const { slides, currentTime } = props;

    const sendCommand = useSendCommand();
    const onLinkClick = (url: string) => sendCommand(OPEN_LINK, url);

    const [event] = slides;

    const timestamp = new Date(
        event?.livestreamStartTime ?? Date.now()
    ).getTime();

    const timeLeft = timestamp - currentTime.getTime();

    return (
        <>
            <div
                className={classNames(
                    "incoming-live-event-banner",
                    styles["incoming-live-event-banner"],
                    {
                        "no-logo": !event?.eventPromotionLogo,
                    }
                )}
            >
                <div className={styles["banner-left"]}>
                    {event?.eventPromotionLogo && (
                        <Image sources={event?.eventPromotionLogo} />
                    )}
                </div>

                <div className={styles["promotion-name"]}>
                    <h3>{event?.eventPromotionName}</h3>
                </div>

                <div className={styles["banner-middle"]}>
                    <div className={styles.time}>
                        <span className={styles.caption}>Live In</span>

                        <span>{formatTime(Math.floor(timeLeft / 1000))}</span>
                    </div>
                </div>

                <div
                    className={styles.subscribe}
                    onClick={() =>
                        onLinkClick(event?.subscribeButton?.url ?? "")
                    }
                >
                    {event?.subscribeButton?.label}
                </div>
            </div>
        </>
    );
};

export { IncomingLiveEventsBannerView };
