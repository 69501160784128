import React from "react";

import { Tab } from "supercell-frontend-base/src/components/inbox/tab";

import { Article } from "supercell-frontend-base/src/components/article";

import styles from "./styles.modules.scss";
import { esportParser } from "supercell-frontend-base/src/parsers/esport";

import { EsportFooter } from "../esport-footer/view";

export const EsportArticleView = ({ id, getProps, track }) => (
    <div className={`article esport-article ${styles.article}`}>
        <Tab namespace="esport" parser={esportParser} id={id}>
            {({ data: { articles, globals, socialLinks }, userSettings }) => {
                const { article, Link, prevLinkProps, nextLinkProps } =
                    getProps({
                        articles,
                        userSettings,
                        globals,
                    });

                track(article);

                return (
                    <>
                        <Article
                            article={article}
                            Link={Link}
                            nextLinkProps={nextLinkProps}
                            prevLinkProps={prevLinkProps}
                        />

                        <EsportFooter links={socialLinks as SocialLink[]} />
                    </>
                );
            }}
        </Tab>
    </div>
);
