import React from "react";

import { Grid } from "supercell-frontend-base/src/components/grid";
import { List } from "supercell-frontend-base/src/components/list";
import { ListArticle } from "supercell-frontend-base/src/components/list/article";
import { Tab } from "supercell-frontend-base/src/components/inbox/tab";

import { esportParser } from "supercell-frontend-base/src/parsers/esport";

import styles from "./styles.modules.scss";
import { EsportCarouselControllerView } from "./esport-carousel/view";
import { IncomingLiveEventsBannerView } from "./esport-incoming-events-banner/view";
import { EsportCarousel } from "supercell-frontend-base/src/components/esport/esport-carousel";
import { IncomingLiveEventsBanner } from "supercell-frontend-base/src/components/esport/esport-incoming-events-banner";
import { EsportSlide } from "supercell-frontend-base/src/components/esport/types";
import { EsportFooter } from "./esport-footer/view";

export const EsportView = () => {
    return (
        <Tab namespace="esport" parser={esportParser}>
            {({ data: { articles, socialLinks, carouselItems }, locale }) => {
                return (
                    <div className={`esport ${styles.esport}`}>
                        <Grid>
                            <div className={`articles ${styles.articles}`}>
                                <List
                                    header={
                                        <>
                                            <IncomingLiveEventsBanner
                                                view={
                                                    IncomingLiveEventsBannerView
                                                }
                                                slides={
                                                    carouselItems as EsportSlide[]
                                                }
                                            />

                                            <EsportCarousel
                                                view={
                                                    EsportCarouselControllerView
                                                }
                                                locale={locale}
                                                slides={
                                                    carouselItems as EsportSlide[]
                                                }
                                            />
                                        </>
                                    }
                                    Item={ListArticle}
                                    items={articles as any[][]}
                                    columns={1}
                                    perPage={4}
                                    footer={
                                        <>
                                            <EsportFooter
                                                links={
                                                    socialLinks as SocialLink[]
                                                }
                                            />
                                        </>
                                    }
                                />
                            </div>
                        </Grid>
                    </div>
                );
            }}
        </Tab>
    );
};
