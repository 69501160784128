import React from "react";

import { localization } from "./config/localization";

import { commander } from "./commander";

import { generateRoute } from "./helper-generate-route";

import { AppView } from "./view";
import { Esport } from "../esport";

const LazyArticle = React.lazy(
    () => import(/* webpackPrefetch: true */ "../article")
);
const LazyCommunity = React.lazy(() => import("../community"));
const LazyNews = React.lazy(() => import("../news"));

export const AppController = ({ sendCommand, trackEvent }) => {
    trackEvent({
        event: "gtm.js",
        "gtm.start": Date.now(),
    });

    trackEvent({
        originalLocation: window.location.href,
    });

    const routes = [
        generateRoute("/news/:id/:slug/:variant", LazyArticle),
        generateRoute("/news/:id/:slug", LazyArticle),
        generateRoute("/news/:id", LazyArticle),
        generateRoute("/esport/*", () => <Esport />),
        generateRoute("/community", LazyCommunity),
        generateRoute("/news", LazyNews),
        generateRoute("/", LazyNews),
    ];

    const props = {
        routes,
        localization,
        commander: commander(sendCommand, trackEvent),
    };

    return <AppView {...props} />;
};
