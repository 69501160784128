import React, { useState, useEffect } from "react";

import { DebugInfoView } from "./view";

export const DebugInfoController = () => {
    const [timesClicked, setTimesClicked] = useState(0);
    const [createdEl, setCreatedEl] = useState(false);

    useEffect(() => {
        if (createdEl) {
            return;
        }

        if (timesClicked >= 3) {
            const el = document.createElement("span");
            el.innerHTML = window.BUILD_INFO.buildId;
            document.body.append(el);
            setCreatedEl(true);
        }
    }, [timesClicked]);

    return <DebugInfoView onClick={() => setTimesClicked(timesClicked + 1)} />;
};
