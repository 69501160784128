import React from "react";
import { useLocation, useParams } from "react-router-dom";

import { TRACK_EVENT } from "supercell-frontend-base/src/constants/types";
import { useSendCommand } from "supercell-frontend-base/src/hooks/use-send-command";
import { useSnowplowContext } from "supercell-frontend-base/src/analytics/snowplow";

import { getProps } from "./get-props";

import { EsportArticleView } from "./view";

type Params = { id: string; variant?: string; slug?: string };

export const EsportArticleController = () => {
    const { pathname: pagePath } = useLocation();
    const { id, variant, slug } = useParams<Params>();
    const sendCommand = useSendCommand();
    const { pageView } = useSnowplowContext();
    const isolated =
        (variant && variant.toLowerCase() === "isolated") ||
        (slug && slug.toLowerCase() === "isolated");

    const track = (article) => {
        const { id: pageId, title: pageTitle } = article;

        pageView(pageTitle);
        sendCommand(TRACK_EVENT, {
            event: "Pageview",
            pageId,
            pagePath,
            pageTitle,
        });
    };

    const props = {
        getProps: getProps(id ?? "", Boolean(isolated)),
        id,
        track,
    };

    return <EsportArticleView {...props} />;
};
