import slugify from "slugify";
import {
    AUDIO_RESUME,
    AUDIO_PAUSE,
    GAME_COMMAND,
    OPEN_HOME,
    OPEN_LINK,
    OPEN_ESPORT_ARTICLE,
    OPEN_NEWS_ARTICLE,
    PLAY_SOUND,
    SHOW_BACK_BUTTON,
    TRACK_EVENT,
} from "supercell-frontend-base/src/constants/types/commands";
import { normalizeLang } from "supercell-frontend-base/src/utils/normalize-lang";

import { mutateOpenLinkHack } from "./hack-mutate-open-link";

const protocol = "clashroyale-inbox";

const mutateOpenLink = mutateOpenLinkHack(protocol);

export const commander =
    (sendGameCommand, trackEvent) => (navigate) => (type, payload) => {
        if (type === OPEN_LINK) ({ type, payload } = mutateOpenLink(payload));

        switch (type) {
            case OPEN_HOME:
                return navigate("/");
            case OPEN_NEWS_ARTICLE:
                return navigate(
                    `/${normalizeLang(payload.lang)}/news/${
                        payload.id
                    }/${slugify(payload.slug ?? "", {
                        strict: true,
                        lower: true,
                    })}`
                );
            case OPEN_ESPORT_ARTICLE:
                return navigate(
                    `/${normalizeLang(payload.lang)}/esport/${
                        payload.id
                    }/${slugify(payload.slug ?? "", {
                        strict: true,
                        lower: true,
                    })}`
                );

            case OPEN_LINK:
                trackEvent({ event: OPEN_LINK, url: payload });

                return sendGameCommand(
                    `${protocol}://OpenWeb?url=${encodeURIComponent(payload)}`
                );
            case PLAY_SOUND:
                return sendGameCommand(
                    `${protocol}://playSound?name=${payload}`
                );
            case AUDIO_PAUSE:
                return sendGameCommand(`${protocol}://StopMusic`);
            case AUDIO_RESUME:
                return sendGameCommand(`${protocol}://ResumeMusic`);
            case SHOW_BACK_BUTTON:
                return sendGameCommand(
                    `${protocol}://backButton?state=${
                        payload ? "show" : "hide"
                    }`
                );
            case TRACK_EVENT:
                return trackEvent(payload);
            case GAME_COMMAND:
                return sendGameCommand(payload);

            default:
                return console.error(`Command not recognized "${type}"`);
        }
    };
