import { Link } from "supercell-frontend-base/src/components/article/navigation/link";

import { TYPE_ARTICLE_ESPORT_NEWS_REGULAR } from "supercell-frontend-base/src/constants/types";
import { filterArticlesByType } from "supercell-frontend-base/src/utils/articles/filter-articles-by-type";

import { getArticleIndex } from "supercell-frontend-base/src/utils/articles/get-article-index";

export const getProps =
    (id: string, isolated: boolean) =>
    ({ articles, globals }) => {
        const newsArticles = filterArticlesByType(articles, [
            TYPE_ARTICLE_ESPORT_NEWS_REGULAR,
        ]);

        const index = getArticleIndex(newsArticles, id);

        const article = newsArticles[index];
        const nextArticle = newsArticles[index - 1];
        const prevArticle = index > -1 && newsArticles[index + 1];

        const prevLinkProps = !isolated
            ? {
                  disabled: !prevArticle,
                  label: globals["globalText.langOlder"],
                  labelComponentProps: {
                      data: prevArticle,
                  },
              }
            : undefined;

        const nextLinkProps = !isolated
            ? {
                  next: true,

                  disabled: !nextArticle,
                  label: globals["globalText.langNewer"],
                  labelComponentProps: {
                      data: nextArticle,
                  },
              }
            : undefined;

        return {
            article,
            Link,
            prevLinkProps,
            nextLinkProps,
        };
    };
